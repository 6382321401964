import { PageProps } from 'gatsby';
import React from 'react';

import { useCustomMixpanel } from '@services/Mixpanel';
import { PaymentDetailsPage } from '@src/modules';

const Payment = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  return <PaymentDetailsPage {...props} mixpanel={mixpanel} />;
};

export default Payment;
